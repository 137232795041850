<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>
<style>
.vdataTablescroll .v-data-table__wrapper::-webkit-scrollbar {
  width: 10px;
  height: 8px;
  background-color: #e6e6e6;
}
.vdataTablescroll ::-webkit-scrollbar-thumb {
  background: #b0b0b0 !important;
  border: solid 3px #e6e6e6 !important;
  border-radius: 7px !important;
}
.cardGradient {
  background-image: linear-gradient(to right, #8534e8, #86c4e1) !important;
}
.cardGradient1 {
  background-image: linear-gradient(to right, #8534e8, #86c4e1) !important;
}
.cardBorderColor {
  border: 1px solid white !important;
  background: none !important;
}
.PrimaryFontColor {
  color: #424242;
  font-size: 20px;
}
.FontSize {
  font-size: 12px !important;
}
.fontSizeSmall {
  font-size: 10px !important;
}
.fontSizeXSmall {
  font-size: 9px !important;
}

.fontSize8px {
  font-size: 8px !important;
}

.fontSize9px {
  font-size: 9px !important;
}

.fontSize10px {
  font-size: 10px !important;
}
.fontSize11px {
  font-size: 11px !important;
}

.fontSize12px {
  font-size: 12px !important;
}
.fontSize14px {
  font-size: 14px !important;
}
.fontSize216px {
  font-size: 16px !important;
}

.fontSize18px {
  font-size: 18px !important;
}

.fontSize20px {
  font-size: 20px !important;
}
.fontSize15px {
  font-size: 15px !important;
}

.fontSize25px {
  font-size: 25px !important;
}

.fontSize35px {
  font-size: 35px !important;
}
.maxWidthSmall {
  max-width: 180px !important;
}
.maxWidthXSmall {
  max-width: 150px !important;
}
.maxWidthXXSmall {
  width: 120px !important;
}
.maxWidthXXXSmall {
  width: 100px !important;
}
.maxWidthSelect1 {
  max-width: 200px !important;
}
.maxWidthSelect2 {
  max-width: 150px !important;
}
.maxWidthSelect {
  max-width: 250px !important;
}
.maxWidthSearch {
  max-width: 250px !important;
}
.borderVariant1 {
  border: 2px solid #8534e8 !important;
}
.borderVariant2 {
  border: 1px solid #000000 !important;
}
.v-input .v-label {
  font-size: 12px;
}
.field_height.v-text-field.v-input_control.v-input_slot {
  min-height: 12px !important;
  display: flex !important;
  align-items: center !important;
}
.field_label_size.v-input .v-label {
  font-size: 12px;
}
.v-select .v-input_control .v-inputslot .v-selectslot .v-select_selections {
  padding: 0 !important;
  min-height: 0 !important;
}

.v-select-list .v-list-item {
  min-height: 0 !important;
}

.v-select-list .v-list-item .v-list-item__content {
  padding: 14px 0 !important;
}
.image-upload-input {
  display: none;
  z-index: -9999;
}
.greyColorVariantOne {
  background-color: #dad8d8 !important;
}
.redColorVariantOne {
  background-color: #fcbcbc !important;
}
.greenColorVariantOne {
  background-color: #c6ffd4 !important;
}
.audioPlayer {
  width: 100% !important;
  height: 50% !important;
}
.excel-upload-input {
  display: none;
  z-index: -9999;
}
.scroll-container .v-navigation-drawer__content {
  overflow: auto !important;
  scrollbar-width: none !important;
  scrollbar-color: transparent !important;
  /* display: none !important; */
}
.scroll-container::-webkit-scrollbar {
  width: 0.1em !important;
}
.scroll-container::-webkit-scrollbar-track {
  background-color: red !important;
}
.scroll-container::-webkit-scrollbar-thumb {
  background-color: blue !important;
}
.small-tab {
  padding: 4px 6px !important;
  font-size: 12px !important;
}

.custom-list-group .v-list-group__header {
  height: 24px !important; /* Adjust the height value as per your requirement */
}

.custom-list-group .v-list-item {
  height: 24px !important; /* Adjust the height value as per your requirement */
}

.cursorPointer {
  cursor: pointer !important;
}

.container {
  min-height: 100vh !important; /* Set minimum container height to 100% of viewport height */
  display: flex !important; /* Use flexbox to align card vertically */
}

.custom-scrollbar {
  overflow-y: auto !important;
  scrollbar-width: thin !important;
  scrollbar-color: #8534e8 #f5f5f5 !important;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px !important;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f5f5f5 !important;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #8534e8 !important;
  border-radius: 4px !important;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #8534e8 !important;
}

.scroll .v-data-table__wrapper::-webkit-scrollbar {
  width: 12px !important;
  height: 12px !important;
  background-color: #ffffff;
  /* color: red; */
}
.scroll .v-data-table__wrapper::-webkit-scrollbar-thumb {
  background: #8534e8 !important;
  border: solid 3px #e6e6e6 !important;
  border-radius: 7px !important;
}

.full-height {
  height: 100vh !important;
}

.full-height-card {
  height: 100% !important;
}

/*     
.CustomizedTableClass table {
  border: 1px solid #f2f2f2 !important;
  border-collapse: collapse !important;
  /* border-radius: 10px !important; 
  overflow: hidden !important;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15) !important;
  margin: 0 auto !important;
}
.CustomizedTableClass table tr:nth-child(even) {
  background-color: #e5f6f0 !important;
}
.CustomizedTableClass table th {
  position: -webkit-sticky !important;
  top: 0 !important;
  z-index: 0 !important;
  background: #01ab6e !important;
  color: #fff !important;
}
* {
  scrollbar-width: thin !important;
}


*/
.tableClass table {
  /* border-collapse: separate;
  border-spacing: 0 4px !important;
  background: #ffffff00 !important; */
  background: #ffffff00 !important;
  border: 1px solid #f2f2f2 !important;
  border-collapse: separate !important;
  border-radius: 0px !important;
  overflow: hidden !important;
  /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.15) !important; */
  margin: 0 0 !important;
  border-spacing: 0px !important;
}
.tableClass th {
  height: 42px !important;
  color: #000000 !important;
  font-size: 12px !important;
  background: #f3f6f9 !important;
}
.tableClass.v-table.v-table--fixed-header > .v-table__wrapper > table > thead > tr > th {
  position: -webkit-sticky !important;
  top: 0 !important;
  z-index: 0 !important;
  background: #f3f6f9 !important;
  color: #242427 !important;
  font-size: 12px !important;
  font-weight: bold !important;
}
.tableClass.v-table > .v-table__wrapper > table > tbody > tr > td > .v-card-actions {
  min-height: 30px !important;
  padding: 1px !important;
  /* color: red !important; */
  font-size: 12px !important;
  /* border-bottom: 1px solid #e6e7e982 !important; */
}
.tableClass td:first-child {
  border-left: 1px solid #e6e7e982 !important;
  /* border-bottom-left-radius: 6px !important; */
}

.tableClass td:last-child {
  border-right: 1px solid #e6e7e982 !important;
  /* border-bottom-right-radius: 6px !important; */
}
.tableClass2 table {
  border-collapse: separate;
  border-spacing: 0 4px !important;
  width: 280% !important;
}
.tableClass2 th {
  background: #f3f6f9 !important;
  border: none !important;
  border-bottom: none !important;
  padding: 1px;
  color: #000000 !important;
  font-size: 12px !important;
}
.tableClass2 td {
  width: 200px !important;
  /* overflow: hidden !important; */
  white-space: nowrap !important;
  border-radius: 0px !important;
  padding: 0px;
}
.WebSticky {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0 !important;
}
.expansionPanel .v-expansion-panel-content .v-expansion-panel-content__wrap {
  padding: 0 !important;
  flex: 1 1 auto;
  max-width: 100%;
}
.create-btn {
  background-color: #d9f5f0;
  color: #00bd9d !important;
  transition: background-color 0.3s;
}

.create-btn:hover {
  color: white !important;
  background-color: #00bd9d !important;
}
.delete-btn {
  background-color: #ffffff;
  color: #9a2835 !important;
  transition: background-color 0.3s;
}
.delete-btn:hover {
  color: white !important;
  background-color: #9a2835 !important;
}
.grey-border {
  border: 1px solid #e5e5e5 !important;
}
</style>
