import VueGoogleMaps from "@fawmi/vue-google-maps";
import { Amplify } from "aws-amplify";
import { createApp } from "vue";
import VueApexCharts from "vue3-apexcharts";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import router from "./router";
import store from "./store";

loadFonts();

Amplify.configure({
  Auth: {
    userPoolId: process.env.VUE_APP_AWS_USER_POOL_ID,
    region: process.env.VUE_APP_AWS_REGION,
    userPoolWebClientId: process.env.VUE_APP_AWS_USER_POOL_WEB_CLIENT_ID,
  },
  API: {
    aws_project_region: process.env.VUE_APP_AWS_APPSYNC_REGION,
    aws_appsync_graphqlEndpoint: process.env.VUE_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT,
    aws_appsync_region: process.env.VUE_APP_AWS_APPSYNC_REGION,
    aws_appsync_authenticationType: process.env.VUE_APP_AWS_APPSYNC_AUTH_TYPE,
    endpoints: [
      {
        name: "Report_issue",
        endpoint: process.env.VUE_APP_REST_API_BASE_URL,
      },
    ],
  },
});
createApp(App)
  .use(store)
  .use(router)
  .use(vuetify)
  .use(VueApexCharts)
  .use(VueGoogleMaps, {
    load: {
      key: process.env.VUE_APP_GOOGLE_MAP_KEY_VOZCARE_PORTAL,
      libraries: "places",
    },
  })
  .mount("#app");
