/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const ListGateways = /* GraphQL */ `
  query ListGateways($input: ListGatewaysInput) {
    ListGateways(input: $input)
  }
`;
export const ListDispositionCodes = /* GraphQL */ `
  query ListDispositionCodes($input: ListDispositionCodesInut) {
    ListDispositionCodes(input: $input)
  }
`;
export const GetDispositionCodeDetails = /* GraphQL */ `
  query GetDispositionCodeDetails($input: GetDispositionCodeDetailsInput) {
    GetDispositionCodeDetails(input: $input)
  }
`;
export const GetCampaignDetails = /* GraphQL */ `
  query GetCampaignDetails($input: GetCampaignDetailsInput) {
    GetCampaignDetails(input: $input)
  }
`;
export const ListCampaignCrmData = /* GraphQL */ `
  query ListCampaignCrmData($input: ListCampaignCrmDataInput) {
    ListCampaignCrmData(input: $input)
  }
`;
export const ListCrmDataUploadedHistory = /* GraphQL */ `
  query ListCrmDataUploadedHistory($input: ListCrmDataUploadedHistoryInput) {
    ListCrmDataUploadedHistory(input: $input)
  }
`;
export const ListCrmData = /* GraphQL */ `
  query ListCrmData($input: ListCrmDataInput) {
    ListCrmData(input: $input)
  }
`;
export const ListCurateCrmData = /* GraphQL */ `
  query ListCurateCrmData($input: ListCurateCrmDataInput) {
    ListCurateCrmData(input: $input)
  }
`;
export const ListPartialUpdateData = /* GraphQL */ `
  query ListPartialUpdateData($input: ListPartialUpdateDataInput) {
    ListPartialUpdateData(input: $input)
  }
`;
export const ListDNDData = /* GraphQL */ `
  query ListDNDData($input: ListDNDDataInput) {
    ListDNDData(input: $input)
  }
`;
export const ListPBTBData = /* GraphQL */ `
  query ListPBTBData($input: ListDNDDataInput) {
    ListPBTBData(input: $input)
  }
`;
export const ListFieldsOfMasterForm = /* GraphQL */ `
  query ListFieldsOfMasterForm($input: ListFieldsOfMasterFormInput) {
    ListFieldsOfMasterForm(input: $input)
  }
`;
export const ListFieldXLDropdowns = /* GraphQL */ `
  query ListFieldXLDropdowns($input: ListFieldXLDropdownsInput) {
    ListFieldXLDropdowns(input: $input)
  }
`;
export const ListProductFields = /* GraphQL */ `
  query ListProductFields($input: ListProductFieldsInput) {
    ListProductFields(input: $input)
  }
`;
export const ListProductForms = /* GraphQL */ `
  query ListProductForms($input: ListProductFormsInput) {
    ListProductForms(input: $input)
  }
`;
export const ListMasterForms = /* GraphQL */ `
  query ListMasterForms($input: ListMasterFormsInput) {
    ListMasterForms(input: $input)
  }
`;
export const ListMasterFields = /* GraphQL */ `
  query ListMasterFields($input: ListMasterFieldsInput) {
    ListMasterFields(input: $input)
  }
`;
export const FilterCommonFields = /* GraphQL */ `
  query FilterCommonFields($input: FilterCommonFieldsInput) {
    FilterCommonFields(input: $input)
  }
`;
export const FilterUniqueFields = /* GraphQL */ `
  query FilterUniqueFields($input: FilterCommonFieldsInput) {
    FilterUniqueFields(input: $input)
  }
`;
export const ListTeamsOrUsersOfCampaign = /* GraphQL */ `
  query ListTeamsOrUsersOfCampaign($input: ListTeamsOrUsersOfCampaignInput) {
    ListTeamsOrUsersOfCampaign(input: $input)
  }
`;
export const ListTeamsToAddCampaign = /* GraphQL */ `
  query ListTeamsToAddCampaign($input: ListTeamsToAddCampaignInput) {
    ListTeamsToAddCampaign(input: $input)
  }
`;
export const ListCountries = /* GraphQL */ `
  query ListCountries($input: ListCountriesInput) {
    ListCountries(input: $input)
  }
`;
export const ListCampaigns = /* GraphQL */ `
  query ListCampaigns($input: ListCampaignsInput) {
    ListCampaigns(input: $input)
  }
`;
export const ListProducts = /* GraphQL */ `
  query ListProducts($input: ListProductsInput) {
    ListProducts(input: $input)
  }
`;
export const ListClient = /* GraphQL */ `
  query ListClient($input: ListClientInput) {
    ListClient(input: $input)
  }
`;
export const ListCuratorClientsOrProducts = /* GraphQL */ `
  query ListCuratorClientsOrProducts(
    $input: ListCuratorClientsOrProductsInput
  ) {
    ListCuratorClientsOrProducts(input: $input)
  }
`;
export const ListRoles = /* GraphQL */ `
  query ListRoles($input: ListRolesInput) {
    ListRoles(input: $input)
  }
`;
export const ListUsersOfRole = /* GraphQL */ `
  query ListUsersOfRole($input: ListUsersOfRoleInput) {
    ListUsersOfRole(input: $input)
  }
`;
export const GetCurrentUserDetails = /* GraphQL */ `
  query GetCurrentUserDetails($input: GetCurrentUserDetailsInput) {
    GetCurrentUserDetails(input: $input)
  }
`;
export const ListModules = /* GraphQL */ `
  query ListModules($input: ListModulesInput) {
    ListModules(input: $input)
  }
`;
export const ListUsers = /* GraphQL */ `
  query ListUsers($input: ListUsersInput) {
    ListUsers(input: $input)
  }
`;
export const ListOnlineOfflineUsers = /* GraphQL */ `
  query ListOnlineOfflineUsers($input: ListOnlineOfflineUsersInput) {
    ListOnlineOfflineUsers(input: $input)
  }
`;
export const ListBlockedUsers = /* GraphQL */ `
  query ListBlockedUsers($input: ListBlockedUsersInput) {
    ListBlockedUsers(input: $input)
  }
`;
export const ListTeams = /* GraphQL */ `
  query ListTeams($input: ListTeamsInput) {
    ListTeams(input: $input)
  }
`;
export const ListSubTeams = /* GraphQL */ `
  query ListSubTeams($input: ListSubTeamsInput) {
    ListSubTeams(input: $input)
  }
`;
export const GetTeamDetails = /* GraphQL */ `
  query GetTeamDetails($input: GetTeamDetailsInput) {
    GetTeamDetails(input: $input)
  }
`;
export const ListUsersToAddTeam = /* GraphQL */ `
  query ListUsersToAddTeam($input: ListUsersToAddTeamInput) {
    ListUsersToAddTeam(input: $input)
  }
`;
export const ListUsersOfTeam = /* GraphQL */ `
  query ListUsersOfTeam($input: ListUsersOfTeamInput) {
    ListUsersOfTeam(input: $input)
  }
`;
export const ListLocations = /* GraphQL */ `
  query ListLocations($input: ListLocationsInput) {
    ListLocations(input: $input)
  }
`;
export const ListTeamsOfLocation = /* GraphQL */ `
  query ListTeamsOfLocation($input: ListTeamsOfLocationInput) {
    ListTeamsOfLocation(input: $input)
  }
`;
export const ListDuplicateCRMData = /* GraphQL */ `
  query ListDuplicateCRMData($input: ListCrmDataInput) {
    ListDuplicateCRMData(input: $input)
  }
`;
export const ListAuditLogs = /* GraphQL */ `
  query ListAuditLogs($input: ListAuditLogsInput) {
    ListAuditLogs(input: $input)
  }
`;
export const GetProductDetails = /* GraphQL */ `
  query GetProductDetails($input: GetProductDetailsInput) {
    GetProductDetails(input: $input)
  }
`;
export const ListModificationRequest = /* GraphQL */ `
  query ListModificationRequest($input: ListModificationRequestsInput) {
    ListModificationRequest(input: $input)
  }
`;
export const GetLeadHistory = /* GraphQL */ `
  query GetLeadHistory($input: GetLeadHistoryInput) {
    GetLeadHistory(input: $input)
  }
`;
export const GetCallAgentReports = /* GraphQL */ `
  query GetCallAgentReports($input: GetCallAgentReportsInput) {
    GetCallAgentReports(input: $input)
  }
`;
export const GetCallHistory = /* GraphQL */ `
  query GetCallHistory($input: GetCallHistoryInput) {
    GetCallHistory(input: $input)
  }
`;
export const GetCampaignCallReports = /* GraphQL */ `
  query GetCampaignCallReports($input: GetCallHistoryInput) {
    GetCampaignCallReports(input: $input)
  }
`;
export const GetTimeSheetOfUser = /* GraphQL */ `
  query GetTimeSheetOfUser($input: GetTimeSheetOfUserInput) {
    GetTimeSheetOfUser(input: $input)
  }
`;
export const GetLeadDetails = /* GraphQL */ `
  query GetLeadDetails($input: GetLeadDetailsInput) {
    GetLeadDetails(input: $input)
  }
`;
export const GetCustomerDetails = /* GraphQL */ `
  query GetCustomerDetails($input: GetCustomerDetailsInput) {
    GetCustomerDetails(input: $input)
  }
`;
export const ListProductMaster = /* GraphQL */ `
  query ListProductMaster($input: ListProductMasterInput) {
    ListProductMaster(input: $input)
  }
`;
export const GetClientDetails = /* GraphQL */ `
  query GetClientDetails($input: GetClientDetailsInput) {
    GetClientDetails(input: $input)
  }
`;
export const GetFilteredCampaignData = /* GraphQL */ `
  query GetFilteredCampaignData($input: GetFilteredCampaignDataInput) {
    GetFilteredCampaignData(input: $input)
  }
`;
export const getFilteredProductData = /* GraphQL */ `
  query GetFilteredProductData($input: GetFilteredProductDataInput) {
    getFilteredProductData(input: $input)
  }
`;
export const ListPriorityOfCampaignUsers = /* GraphQL */ `
  query ListPriorityOfCampaignUsers($input: ListPriorityOfCampaignUsersInput) {
    ListPriorityOfCampaignUsers(input: $input)
  }
`;
export const ListTeamsOrUsersOfProduct = /* GraphQL */ `
  query ListTeamsOrUsersOfProduct($input: ListTeamsOrUsersOfProductInput) {
    ListTeamsOrUsersOfProduct(input: $input)
  }
`;
export const ListDashboardReport = /* GraphQL */ `
  query ListDashboardReport($input: ListDashboardReportInput) {
    ListDashboardReport(input: $input)
  }
`;
export const ListCallerIds = /* GraphQL */ `
  query ListCallerIds($input: ListCallerIdsInput) {
    ListCallerIds(input: $input)
  }
`;
export const ExportCRMData = /* GraphQL */ `
  query ExportCRMData($input: ExportCRMDataInput) {
    ExportCRMData(input: $input)
  }
`;
export const ExportCampaignCRMData = /* GraphQL */ `
  query ExportCampaignCRMData($input: ExportCRMDataInput) {
    ExportCampaignCRMData(input: $input)
  }
`;
export const ExportCampaignCallReports = /* GraphQL */ `
  query ExportCampaignCallReports($input: ExportCampaignCallReportsInput) {
    ExportCampaignCallReports(input: $input)
  }
`;
export const GenerateUploadedDataReports = /* GraphQL */ `
  query GenerateUploadedDataReports($input: GenerateUploadedDataReportsInput) {
    GenerateUploadedDataReports(input: $input)
  }
`;
export const GetCallRecordingReport = /* GraphQL */ `
  query GetCallRecordingReport($input: GetCallRecordingReportInput) {
    GetCallRecordingReport(input: $input)
  }
`;
export const ExportCallRecordingReport = /* GraphQL */ `
  query ExportCallRecordingReport($input: GetCallRecordingReportInput) {
    ExportCallRecordingReport(input: $input)
  }
`;
export const ExportTimeSheetOfUser = /* GraphQL */ `
  query ExportTimeSheetOfUser($input: ExportTimeSheetOfUserInput) {
    ExportTimeSheetOfUser(input: $input)
  }
`;
export const ExportDcwncAwdccReport = /* GraphQL */ `
  query ExportDcwncAwdccReport($input: ExportDcwncAwdccReportInput) {
    ExportDcwncAwdccReport(input: $input)
  }
`;
export const ListDcwncAwdccLeads = /* GraphQL */ `
  query ListDcwncAwdccLeads($input: ListDcwncAwdccLeadsInput) {
    ListDcwncAwdccLeads(input: $input)
  }
`;
export const GetCampaignsOfProduct = /* GraphQL */ `
  query GetCampaignsOfProduct($input: GetCampaignsOfProductInput) {
    GetCampaignsOfProduct(input: $input)
  }
`;
export const ListEmails = /* GraphQL */ `
  query ListEmails($input: ListEmailsInput) {
    ListEmails(input: $input)
  }
`;
export const ListBreakType = /* GraphQL */ `
  query ListBreakType($input: ListBreakTypeInput) {
    ListBreakType(input: $input)
  }
`;
export const GetMappedAccounts = /* GraphQL */ `
  query GetMappedAccounts($input: GetMappedAccountsInput) {
    GetMappedAccounts(input: $input)
  }
`;
export const ListEmailTemplates = /* GraphQL */ `
  query ListEmailTemplates($input: ListEmailTemplatesInput) {
    ListEmailTemplates(input: $input)
  }
`;
export const ListCampaignTemplates = /* GraphQL */ `
  query ListCampaignTemplates($input: ListCampaignTemplatesInput) {
    ListCampaignTemplates(input: $input)
  }
`;
export const ListVoiceBlasterTemplates = /* GraphQL */ `
  query ListVoiceBlasterTemplates($input: ListVoiceBlasterTemplatesInput) {
    ListVoiceBlasterTemplates(input: $input)
  }
`;
export const ListFilters = /* GraphQL */ `
  query ListFilters($input: ListFiltersInput) {
    ListFilters(input: $input)
  }
`;
export const GetDispositionMetrics = /* GraphQL */ `
  query GetDispositionMetrics($input: GetDispositionMetricsInput) {
    GetDispositionMetrics(input: $input)
  }
`;
export const ListFilteredData = /* GraphQL */ `
  query ListFilteredData($input: ListFilteredDataInput) {
    ListFilteredData(input: $input)
  }
`;
export const ListDispositionMetricsData = /* GraphQL */ `
  query ListDispositionMetricsData($input: ListDispositionMetricsDataInput) {
    ListDispositionMetricsData(input: $input)
  }
`;
export const ListSkipTracingMetricsData = /* GraphQL */ `
  query ListSkipTracingMetricsData($input: ListSkipTracingMetricsDataInput) {
    ListSkipTracingMetricsData(input: $input)
  }
`;
export const ListNoOfAttemptsMetricsData = /* GraphQL */ `
  query ListNoOfAttemptsMetricsData($input: ListNoOfAttemptsMetricsDataInput) {
    ListNoOfAttemptsMetricsData(input: $input)
  }
`;
export const GetVoiceBlastCallReport = /* GraphQL */ `
  query GetVoiceBlastCallReport($input: GetVoiceBlastCallReportInput) {
    GetVoiceBlastCallReport(input: $input)
  }
`;
export const ListLists = /* GraphQL */ `
  query ListLists($input: ListListsInput) {
    ListLists(input: $input)
  }
`;
export const ListAgentsForFilter = /* GraphQL */ `
  query ListAgentsForFilter($input: ListAgentsForFilterInput) {
    ListAgentsForFilter(input: $input)
  }
`;
export const ListReportData = /* GraphQL */ `
  query ListReportData($input: ListReportDataInput) {
    ListReportData(input: $input)
  }
`;
export const DownloadFilterReport = /* GraphQL */ `
  query DownloadFilterReport($input: DownloadFilterReportInput) {
    DownloadFilterReport(input: $input)
  }
`;
export const ListClientsForFilter = /* GraphQL */ `
  query ListClientsForFilter($input: ListClientsForFilterInput) {
    ListClientsForFilter(input: $input)
  }
`;
export const ListCampaignsForFilter = /* GraphQL */ `
  query ListCampaignsForFilter($input: ListCampaignsForFilterInput) {
    ListCampaignsForFilter(input: $input)
  }
`;
export const ListProductsForFilter = /* GraphQL */ `
  query ListProductsForFilter($input: ListCampaignsForFilterInput) {
    ListProductsForFilter(input: $input)
  }
`;
export const ListSessionFilterMetrics = /* GraphQL */ `
  query ListSessionFilterMetrics($input: ListSessionFilterMetricsInput) {
    ListSessionFilterMetrics(input: $input)
  }
`;
export const ListNotifications = /* GraphQL */ `
  query ListNotifications($input: ListNotificationsInput) {
    ListNotifications(input: $input)
  }
`;
export const GetUserColumnDetails = /* GraphQL */ `
  query GetUserColumnDetails($input: GetUserColumnDetailsInput) {
    GetUserColumnDetails(input: $input)
  }
`;
export const GetTeamUsersLeadsCountDashBoard = /* GraphQL */ `
  query GetTeamUsersLeadsCountDashBoard(
    $input: GetTeamUsersLeadsCountDashBoardInput
  ) {
    GetTeamUsersLeadsCountDashBoard(input: $input)
  }
`;
export const ListReassignFilter = /* GraphQL */ `
  query ListReassignFilter($input: ListReassignFilterInput) {
    ListReassignFilter(input: $input)
  }
`;
export const ListReassignFilterData = /* GraphQL */ `
  query ListReassignFilterData($input: ListReassignFilterDataInput) {
    ListReassignFilterData(input: $input)
  }
`;
export const listSipServers = /* GraphQL */ `
  query ListSipServers($input: ListSipServersInput) {
    listSipServers(input: $input)
  }
`;
